<template>
  <div>
    <b-row>
      <b-col cols="6" md="2" v-for="file in fileList" :key="file.name">
        <b-card @click="updateAlertBox()">
          <div class="d-flex justify-content-end" style="margin-bottom: 4px">
            <!-- <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item>
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{ $t('detail') }}</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown> -->
          </div>
          <div class="d-flex flex-column align-items-center">
            <feather-icon
              size="48"
              icon="FolderIcon"
              class="mb-1"
              color="gold"
            />
            <h5>{{ file.name }}</h5>
            <div class="d-flex justify-content-between w-100 mt-1">
              <span><b>1</b> Files</span>
              <span><b>1</b> MB</span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-card no-body class="mb-0">
      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>


          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <feather-icon
              :icon="resolveIcon(data.item.name)"
              :color="resolveVariant(data.item.name)"
              style="margin-right: 5px"
            />
            {{ data.item.name }}
          </div>
        </template>

        <template #cell(fileSize)="data"> {{ data.item.fileSize }} </template>

        <template #cell(recentDate)="data">
          {{ data.item.recentDate }}
        </template>

        <template>
          <b-link>
            <feather-icon
              icon="FileTextIcon"
              size="16"
              class="align-middle text-body ml-2"
              style="cursor: pointer"
            />
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("to") }} {{ dataMeta.of }}
              {{ $t("of") }}</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card> -->
  </div>
</template>
<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useReport from "./useReport";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    StatisticCardVertical,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BTabs,
    BTab,
    vSelect,
  },
  setup() {
    const fileList = ref([
      {
        name: "ใบลาออก",
        size: "1MB",
      },
      {
        name: "ใบประเมิน",
        size: "1MB",
      },
    ]);

    const updateAlertBox = () => {
      Swal.fire({
        icon: "info",
        title: "กำลังอัพเดท!",
      });
    };

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveVariant,
      resolveIcon,
      resolveUserStatusVariant,
    } = useReport();

    return {
      // Sidebar

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveVariant,
      resolveIcon,
      resolveUserStatusVariant,

      fileList,
      updateAlertBox,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.dropdown-toggle-no-caret {
  padding: 0px !important;
}
</style>
